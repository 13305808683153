<template>
  <div class="FormSection">
    <SectionHeadline
      :text="title"
      :extraText="extraText" />
    <slot />
  </div>
</template>

<script>
import SectionHeadline from '@/components/FormElements/SectionHeadline.vue'

export default {
  name: 'FormSection',
  props: {
    title: {
      type: String,
      required: true,
    },
    extraText: {
      type: String,
      required: false,
      default: '',
    },
  },
  components: { SectionHeadline },

}
</script>

<style lang="stylus" scoped>
  .FormSection
    display block
    margin-bottom 25px
</style>
