<template>
  <form
    class="ProjectDetailsForm"
    @submit.prevent="onSubmit">
    <!-- Case number -->
    <FormSection :title="caseNumberTitle">
      <div class="Row">
        <InputField
          name="caseNumber"
          :value="customCaseNumber"
          :isRequired="currentScreeningData.useMandatoryManualCaseNumbers"
          :placeholder="currentScreeningData.useMandatoryManualCaseNumbers ? '' : caseNumber"
          @on-update="onCustomCaseNumberUpdate" />
        <ToolTip :texts="caseNumberTexts" />
      </div>
      <span
        class="Note"
        v-if="caseNumberNoteText">{{ caseNumberNoteText }}</span>
    </FormSection>

    <!-- Enterprise ID -->
    <FormSection :title="`${ mixWB('ENTERPRISE_ID')}`">
      <div class="Row">
        <InputField
          name="enterpriseID"
          :value="enterpriseID"
          :placeholder="mixWB('ENTERPRISE_ID')"
          @on-update="onEnterpriseIDUpdate" />
        <ToolTip :texts="enterpriseIDTexts" />
      </div>
    </FormSection>

    <!-- Screener -->
    <FormSection
      :title="`${ mixWB('SCREENER')}`"
      v-if="!isDemoUser">
      <div class="Row">
        <Dropdown
          name="screeners"
          :options="screeners"
          :value="selectedScreener"
          @on-update="onScreenerSelect" />
        <ToolTip :texts="screenerTexts" />
      </div>
    </FormSection>

    <!-- Type of project -->
    <FormSection :title="`${mixWB('TYPE_OF_PROJECT')} (${ mixWB('REQUIRED')})`">
      <div
        class="RadioButtonWrap"
        v-for="option in projectTypeOptions"
        :key="option.id">
        <RadioButton
          groupName="project-type"
          :text="option.text"
          :value="option.id"
          :selectedValue="projectType"
          @radio-button-click="onRadioButtonClick" />
      </div>
    </FormSection>

    <!-- Extra options -->
    <FormSection :title="mixWB('OPTIONS')">
      <div
        class="Row CheckBoxRow"
        v-if="showScreeningOption">
        <Checkbox
          name="isScreening"
          :text="mixWB('ONLY_A_SCREENING')"
          :extraText="mixWB('FOR_OCCURRENCES_OF_ENVIRONMENTALLY_PROBLEMATIC_SUBSTANCES')"
          :isSelected="isScreening"
          :isDisabled="isIsScreeningCheckBoxDisalbed"
          @checkbox-click="onIsScreeningClick" />
        <ToolTip :texts="screeningTexts" />
      </div>
      <div class="Row CheckBoxRow">
        <Checkbox
          name="isSkipAmount"
          :text="mixWB('AUTOMATICALLY_SKIP_QUANTITIES')"
          :isSelected="isSkipAmount"
          @checkbox-click="isSkipAmount = !isSkipAmount" />
        <ToolTip :texts="skipAmountsTexts" />
      </div>
    </FormSection>

    <!-- Milva Market -->
    <FormSection
      v-if="showMilvaMarketOption"
      :title="mixWB('MILVA_MARKET')">
      <MilvaMarketForm
        :marketData="market"
        @market-form-update="onMilvaMarketUpdate" />
    </FormSection>

    <!-- Errors -->
    <span
      class="ErrorText"
      v-if="errorText">{{ errorText }}</span>

    <!-- Screening warning -->
    <div
      class="ScreeningWarning"
      v-if="showScreeningWarning">
      <p>{{ mixWB('PROJECT_LOCKED_AS_SCREENING_TEXT') }}</p>
    </div>

    <!-- Button -->
    <div class="ButtonWrap">
      <Button
        type="submit"
        :text="buttonText"
        :isLoading="isLoading" />
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import Dropdown from '@/components/FormElements/Dropdown.vue'
import RadioButton from '@/components/FormElements/RadioButton.vue'
import Button from '@/components/Buttons/Button.vue'
import InputField from '@/components/FormElements/InputField.vue'
import ToolTip from '@/components/ToolTip.vue'
import FormSection from '@/components/FormElements/FormSection.vue'
import Checkbox from '@/components/FormElements/Checkbox.vue'
import MilvaMarketForm from '@/components/Forms/MilvaMarketForm.vue'

export default {
  name: 'ProjectDetailsForm',
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      caseNumber: '',
      customCaseNumber: '',
      enterpriseID: '',
      selectedScreener: '',
      projectType: '',
      isScreening: false,
      isSkipAmount: false,
      market: {
        isApproved: false,
        contactEmail: '',
        expiryDate: '',
      },
      errorText: '',

      enterpriseIDTexts: [
        this.mixWB('TOOL_TIP_ENTERPRISE_ID_1'),
        this.mixWB('TOOL_TIP_ENTERPRISE_ID_2'),
        this.mixWB('TOOL_TIP_ENTERPRISE_ID_3'),
      ],
      screenerTexts: [
        this.mixWB('TOOL_TIP_SCREENER_1'),
        this.mixWB('TOOL_TIP_SCREENER_2'),
      ],
      screeningTexts: [
        this.mixWB('TOOL_TIP_SCREENING_1'),
        this.mixWB('TOOL_TIP_SCREENING_2'),
        this.mixWB('TOOL_TIP_SCREENING_3'),
      ],
      skipAmountsTexts: [
        this.mixWB('TOOL_TIP_SKIP_AMOUNTS_1'),
        this.mixWB('TOOL_TIP_SKIP_AMOUNTS_2'),
      ],
    }
  },
  computed: {
    ...mapGetters([
      'currentScreeningData',
      'currentAccount',
      'metaData',
      'nextCaseNumber',
      'allUsers',
      'currentUser',
      'isDemoUser',
    ]),
    caseNumberTitle() {
      return this.currentScreeningData.useMandatoryManualCaseNumbers
        ? `${ this.mixWB('CASE_NUMBER') } (${ this.mixWB('REQUIRED') })`
        : this.mixWB('CASE_NUMBER')
    },
    caseNumberTexts() {
      return this.currentScreeningData.useMandatoryManualCaseNumbers
        ? [
          this.mixWB('TOOL_TIP_CASE_NUMBER_3'),
        ]
        : [
          this.mixWB('TOOL_TIP_CASE_NUMBER_1'),
          this.mixWB('TOOL_TIP_CASE_NUMBER_2'),
          this.mixWB('TOOL_TIP_CASE_NUMBER_3'),
        ]
    },
    caseNumberNoteText() {
      if (this.currentAccount.useSamplingID) {
        return ''
      }

      if (!this.currentScreeningData.isStarted) {
        return this.mixWB('CASE_NUMBER_NOT_STARTED_NOTE_TEXT')
      }

      return this.mixWB('CASE_NUMBER_STARTED_NOTE_TEXT')
    },
    screeners() {
      return this.allUsers.reduce((prev, user) => {
        if (user.id === this.currentUser.id && this.currentUser.restrictions.isAdmin) {
          prev.push({
            value: user.id,
            name: `${ user.fullName } (admin)`,
          })
        }
        else if (user.restrictions.isScreener) {
          prev.push({
            value: user.id,
            name: user.fullName,
          })
        }

        return prev
      }, [
        { value: '', name: this.mixWB('SELECT_SCREENER') },
      ])
    },
    projectTypeOptions() {
      const options = []

      // Insert normal options
      const isSSG = this.currentAccount.id === 'uDQYwpFGwFrwkHfaVSWg'

      if (!isSSG || this.currentScreeningData.projectType !== 'damage-related-screening') {
        options.push({
          id: 'renovation',
          text: this.mixWB('RENOVATION'),
        })
        options.push({
          id: 'total-demolition',
          text: this.mixWB('TOTAL_DEMOLITION'),
        })
      }

      if (isSSG) {
        options.push({
          id: 'damage-related-screening',
          text: this.mixWB('DAMAGE_RELATED_ENVIRONMENTAL_SCREENING'),
        })
      }

      return options
    },
    showScreeningOption() {
      if (this.currentScreeningData.isScreening) {
        return true
      }

      // Check for SSG
      if (this.currentAccount.id === 'uDQYwpFGwFrwkHfaVSWg') {
        return false
      }

      return true
    },
    showMilvaMarketOption() {
      // Check for not isScreening and not SSG
      return !this.isScreening && this.currentAccount.id !== 'uDQYwpFGwFrwkHfaVSWg'
    },
    showScreeningWarning() {
      return !this.currentScreeningData.isScreening && this.isScreening
    },
    isIsScreeningCheckBoxDisalbed() {
      return this.currentScreeningData.isScreening
    },
  },
  methods: {
    onSubmit() {
      // Validation
      // - Case Number
      if (this.currentScreeningData.useMandatoryManualCaseNumbers && !this.customCaseNumber) {
        this.errorText = this.mixWB('W: Indtast sagsnummer')
        return
      }

      // - Project type
      if (!this.projectType) {
        this.errorText = this.mixWB('SELECT_TYPE_OF_PROJECT')
        return
      }

      // - Milva Market
      if (this.market.isApproved) {
        if (!this.market.contactEmail) {
          this.errorText = this.mixWB('ERROR_EMAIL_IS_MISSING')
          return
        }
        if (!this.market.expiryDate) {
          this.errorText = this.mixWB('ERROR_EXPIRY_DATE_MISSING')
          return
        }
      }

      // Save data
      this.$emit('form-submit', {
        customCaseNumber: this.customCaseNumber,
        enterpriseID: this.enterpriseID,
        selectedScreener: this.selectedScreener,
        projectType: this.projectType,
        isScreening: this.isScreening,
        isSkipAmount: this.isSkipAmount,
        market: this.market,
      })
    },
    onCustomCaseNumberUpdate({ value }) {
      const updatedValue = value.replaceAll(' ', '-')
      const forbiddenChars = /[^a-z\dæøåÆØÅ\-.\\_]/ig

      if (forbiddenChars.test(updatedValue)) {
        const oldText = this.customCaseNumber
        this.customCaseNumber = '-'
        requestAnimationFrame(() => {
          this.customCaseNumber = oldText
        })
      }
      else {
        this.customCaseNumber = updatedValue
      }
    },
    onEnterpriseIDUpdate({ value }) {
      const updatedValue = value.replaceAll(' ', '-')
      const forbiddenChars = /[^a-z\dæøåÆØÅ\-.\\_]/ig

      if (forbiddenChars.test(updatedValue)) {
        const oldText = this.enterpriseID
        this.enterpriseID = '-'
        requestAnimationFrame(() => {
          this.enterpriseID = oldText
        })
      }
      else {
        this.enterpriseID = updatedValue
      }
    },
    onScreenerSelect({ value }) {
      this.selectedScreener = value
    },
    onRadioButtonClick(value) {
      this.errorText = ''
      this.projectType = value
    },
    onIsScreeningClick() {
      this.isScreening = !this.isScreening

      if (this.isScreening) {
        this.market.isApproved = false
      }
    },
    onMilvaMarketUpdate(marketData) {
      this.market = marketData
    },
    setDataOnLoad() {
      this.caseNumber = this.currentScreeningData.caseNumber
      this.customCaseNumber = this.currentScreeningData.customCaseNumber
      this.enterpriseID = this.currentScreeningData.enterpriseID
      this.projectType = this.metaData.projectType || ''
      this.isScreening = this.currentScreeningData.isScreening
      this.isSkipAmount = this.currentScreeningData.isSkipAmount
      this.market = {
        isApproved: this.currentScreeningData.market.isApproved,
        contactEmail: this.currentScreeningData.market.contactEmail,
        expiryDate: this.currentScreeningData.market.expiryDate,
      }

      // Set screener
      if (this.currentScreeningData.userID) {
        this.selectedScreener = this.currentScreeningData.userID
      }
      else if (this.currentUser.restrictions.isScreener) {
        this.selectedScreener = this.currentUser.id
      }
    },
  },
  components: {
    RadioButton,
    Button,
    InputField,
    Dropdown,
    ToolTip,
    FormSection,
    Checkbox,
    MilvaMarketForm,
  },
  created() {
    this.setDataOnLoad()
  },
}
</script>

<style lang="stylus" scoped>
  .Row
    display flex
    width 100%
    >>> .InputField,
    >>> .Dropdown
      flex-grow 2
    >>> .ToolTip
      margin-left 5px
    &.CheckBoxRow
      >>> .ToolTip
        margin-top -5px
  .Note
    font-size 0.875rem
    color $color_grey
    margin-top -5px
    margin-bottom 10px
    margin-right 40px

  .ErrorText
    display block
    margin-bottom 10px
    color $color_error
    font-size 0.875rem

  .ScreeningWarning
    background-color $color_yellow_lightest
    border 1px solid $color_yellow
    padding 10px
    border-radius 2px
    p
      text-align center
      max-width 500px
      margin 0 auto
      &:last-child
        padding-bottom 0

  .ButtonWrap
    margin-top 20px

</style>
