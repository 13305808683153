<template>
  <div
    v-if="!isPageLoading"
    class="ScreeningsUpdateProjectDetails">
    <div class="InnerPage">
      <CloseButton />

      <div class="NormalPageTitle">{{ mixWB('PROJECT_DETAILS') }}</div>

      <ProjectDetailsForm
        :buttonText="next.text"
        :isLoading="isLoading"
        @form-submit="onFormSubmit" />

    </div>
  </div>
</template>

<script>
import ProjectDetailsForm from '@/components/Forms/ProjectDetailsForm.vue'
import CloseButton from '@/components/ScreeningItems/CloseButton.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ScreeningsUpdateProjectDetails',
  data() {
    return {
      isPageLoading: true,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentScreening',
      'noOfSelectedUnits',
      'currentScreeningData',
      'currentScreeningLoaded',
    ]),
    data() {
      return this.currentScreening.data
    },
    address() {
      if (this.data) {
        return `${ this.data.address.address }, ${ this.data.address.postalCode } ${ this.data.address.city }`
      }
      return ''
    },
    next() {
      if (this.currentScreeningData.isStarted) {
        return {
          text: this.mixWB('SAVE'),
          path: { name: 'ScreeningsUpdate', params: { screeningID: this.currentScreening.id } },
        }
      }
      if (!this.noOfSelectedUnits) {
        return {
          text: this.mixWB('NEXT'),
          path: { name: 'ScreeningsUpdateBuildings', params: { screeningID: this.currentScreening.id } },
        }
      }
      return {
        text: this.mixWB('SAVE'),
        path: { name: 'ScreeningsUpdate', params: { screeningID: this.currentScreening.id } },
      }
    },
  },
  methods: {
    onFormSubmit(projectInfo) {
      this.isLoading = true

      this.$store.dispatch('setProjectDetails', projectInfo)

      this.$router.push(this.next.path)
    },
  },
  components: {
    CloseButton,
    ProjectDetailsForm,
  },
  mounted() {
    const waitForLoad = setInterval(() => {
      if (!this.currentScreeningLoaded) {
        return
      }

      clearInterval(waitForLoad)
      this.isPageLoading = false
    }, 100)
  },
}
</script>

<style lang="stylus" scoped>
  .ScreeningsUpdateProjectDetails
    page-wrap-outer-1()

  .InnerPage
    page-wrap-inner-1()
</style>
