<template>
  <label
    class="RadioButton">
    <input
      class="HiddenInput"
      type="radio"
      :value="value"
      :checked="isSelected"
      :name="groupName"
      @click="onRadioButtonClick" />
    <span
      :class="{ 'IsActive': isSelected }"
      class="RadioIcon"/>
    <span class="RadioText">{{ text }}</span>
  </label>
</template>

<script>
export default {
  name: 'RadioButton',
  props: {
    text: {
      type: String,
      required: true,
    },
    groupName: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    selectedValue: {
      type: String,
      required: true,
    },
  },
  computed: {
    isSelected() {
      return this.selectedValue === this.value
    },
  },
  methods: {
    onRadioButtonClick() {
      this.$emit('radio-button-click', this.value)
    },
  },
}
</script>

<style lang="stylus" scoped>
  .RadioButton
    display flex
    align-items center
    cursor pointer
    margin-bottom 10px

  .HiddenInput
    display none

  .RadioIcon
    position relative
    circle(32px)
    flex-center-children()
    flex-shrink 0
    background-color #fff
    border 1px solid $color_grey_light
    margin-right 10px
    &.IsActive
      &:after
        content ''
        circle(60%)
        background-color $color_primary

  .RadioText
    font-size 1.125rem
</style>
